<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'short-description-wrapper': true,
  }">
    <div class="price-wrapper">
      <div class="regular-price d-flex" v-if="product.hasOwnProperty('promoPrice') && parseFloat(product.promoPrice) > 0">
        {{ product.price }} {{ $t('currency') }}
        <LabelDiscountGreen class="ms-1" :product="product"/>
      </div>
      <div class="d-md-flex">
        <div class="discounted-price">
          {{ product.hasOwnProperty('promoPrice') && parseFloat(product.promoPrice) > 0 ? product.promoPrice : product.price }}<span>
          {{ $t('currency') }}</span>
        </div>
        <div class="d-flex ms-md-3 align-items-center" v-if="product.hasOwnProperty('oldPrice')">
          <div class="dollar-sign">$</div>
          <div class="you-saved">{{ $t('you-saved') }}: <span> {{ product.promoPrice - product.price }}{{
              $t('currency')
            }} </span></div>
        </div>
      </div>
    </div>

    <div class="buy-now-wrapper row">
      <div class="col-md-3 col-6">
        <div class="order-quantity">{{ $t('quantity') }}:</div>
        <div class="d-flex align-items-end">
          <PlusMinusInput v-model="quantity"/>
          <span class="order-piece"> {{ $t('order-piece') }}</span>
        </div>

      </div>
      <div class="col-md-4 d-none d-md-block">
        <Button :text="$t('buy-now')" :svg="'bag'" :theme="'solid-orange'" :size="'medium'"
                @click="addToCart(product.id)"/>
      </div>
      <div class="add-favorites d-flex justify-content-center  col-md-3 col-6">
        <AddToFavourites ref="favorites" size="small" class="pe-2" :product="{
            id: product.id,
            image: product.mainImage.src,
            labeldiscount: promoPrice !== null,
            title: product.title,
            category: product.categories[0].title,
            labelnew: false,
            regularamount: product.price,
            discountedamount: product.promoPrice,
        }"/>
        <span @click="$refs.favorites.toggleFavourites()">{{ $t('add-to-favorites') }}</span>
      </div>
      <div class="buy-now-button col-md-4 col-12 d-md-none">
        <Button :text="$t('buy-now')" :svg="'bag'" :theme="'solid-orange'" :size="'medium'" @click="addToCart(product.id)"/>
      </div>
    </div>
  </div>
</template>
