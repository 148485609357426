<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="label-discount">
    <div class="label-shape">
      <svg width="42" height="17" viewBox="0 0 42 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.47894 1.39945C5.02843 0.528339 5.98637 0 7.01631 0H39C40.6569 0 42 1.34315 42 3V14C42 15.6569 40.6569 17 39 17H7.01632C5.98637 17 5.02843 16.4717 4.47894 15.6005L1.0096 10.1005C0.392767 9.12266 0.392768 7.87734 1.00961 6.89946L4.47894 1.39945Z" fill="#9BEA8E"/>
      </svg>
    </div>
    <div class="discount-amount">-{{ parseInt((product.price - product.promoPrice) / product.price * 100) }}%</div>
  </div>
</template>

