<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'product-title-id-wrapper': true,
  }">
    <div class="price-wrapper">
      <div class="single-product-title">
        {{ product.title }}
      </div>
      <div class="single-product-cat-id">
        {{ $t('product-id') }}: {{ product.sku }}
      </div>
      <div class="single-product-cat-id">
        {{ $t('categories')}}: &nbsp;
        <router-link v-for="(category, index) in product.categories"
                     @key="index"
                     :to="{name: 'MarketplaceCategoryPage', params: {id: category.id}}">
          {{ $t(category.title) }}
          {{ index < product.categories.length - 1 ? ', ' : '' }}
        </router-link>
      </div>
    </div>



  </div>

</template>

