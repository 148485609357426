import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";

export default {
    name: 'ProductCharacteristics',
    props: {
        product: {
            type: Object,
            default: {}
        },
        title: {
            type: String,
            default: 'medium'
        },
        option: {
            type: String,
            default: ''
        },
    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox

    },
    data() {
        return {
            expanded: false,
            specifications: [],
            translation: {
                characteristics_title: 'Характеристики',
            },
            uniqueSpecifications: []
        }
    },
    mounted() {
        const uniqueTitles = [];
        this.uniqueSpecifications = this.$props.product.specifications.filter((item) => {
            if (!uniqueTitles.includes(item.title)) {
                uniqueTitles.push(item.title);
                return true;
            }
            return false;
        });
        this.specifications = this.uniqueSpecifications.slice(0, 3);
    },
    methods: {
        toggleSpecs() {
            if (this.expanded) {
                this.specifications = this.uniqueSpecifications.slice(0, 3);
            } else {
                this.specifications = this.uniqueSpecifications;
            }
            this.expanded = !this.expanded;
        }
    }
}